import React from "react";
import SideBar from "./side-bar";
import Content from "./content";
import Header from "./header";
import { useSelector } from "react-redux";
import { status } from "../features";
import { Spin } from "antd";

function PageContent() {
  return (
    <div className="flex">
      <SideBar />
      <div className="w-full h-screen overflow-x-auto">
        <Header />
        {useSelector(status.status) && (
          <div className="flex justify-center my-2">
            <Spin />
          </div>
        )}

        <Content />
      </div>
    </div>
  );
}

export default PageContent;
