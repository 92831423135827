import { BrowserRouter } from "react-router-dom";
import "./App.css";
import PageContent from "./layout";
import { getToken } from "./utils/user-token";
import Login from "./pages/welcome";

function App() {
  if (getToken().length === 0) return <Login />;
  return (
    <div className="App">
      <BrowserRouter>
        <PageContent />
      </BrowserRouter>
    </div>
  );
}

export default App;
