import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: false,
  sideBar: false,
};

export const statusSlice = createSlice({
  name: "status",
  initialState: initialState,
  reducers: {
    addStatus: (state, action) => {
      state.status = action.payload;
    },
    addSideBar: (state, action) => {
      state.sideBar = action.payload;
    },
  },
});

export const { addStatus, addSideBar } = statusSlice.actions;

export const status = {
  status: (state) => state.status.status,
  setStatus: (data) => (dispatch) => {
    dispatch(addStatus(data));
  },

  sideBar: (state) => state.status.sideBar,
  setSideBar: (data) => (dispatch) => {
    dispatch(addSideBar(data));
  },
};

export default statusSlice.reducer;
