import { base_url, pieme_base_url, token } from "../utils/constants";

async function postData(data = {}) {
  try {
    const response = await fetch(base_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const resp = await response.json();
    return resp;
  } catch (e) {
    return { success: 0 };
  }
}

export async function postAuthData(data = {}) {
  try {
    const response = await fetch(pieme_base_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const resp = await response.json();
    return resp;
  } catch (e) {
    return { success: 0 };
  }
}

export default postData;
