import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboard: {},
  tone: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    addData: (state, action) => {
      state.dashboard = action.payload;
    },
    addTone: (state, action) => {
      state.tone = action.payload;
    },
  },
});

export const { addData, addTone } = dashboardSlice.actions;

export const dashboard = {
  dashboard: (state) => state.dashboard.dashboard,
  tone: (state) => state.dashboard.tone,
  setDashboard: (data) => (dispatch) => {
    dispatch(addData(data));
  },
  setTone: (data) => (dispatch) => {
    dispatch(addTone(data));
  },
};

export default dashboardSlice.reducer;
