import React, { useState, useEffect } from "react";
import postData from "../../hooks/fetchApi";
import { status } from "../../features";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  formatDateTime,
  numberWithCommas,
  numberWithCommasExtra,
} from "../../utils/utils";
import { Pagination } from "antd";
import CreateUnit from "./create-unit";
import UnitDetails from "./unit-details";

function Accommodations() {
  document.title = "Residences";
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [param, setParam] = useState({
    property: "all",
    items: "10",
    offset: "0",
    search: "",
    currency: "",
    price_range: {
      min: "0",
      max: "0",
    },
  });

  const getData = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "get_accommodation",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success === 1) {
        setData(data.data);
        setTotal(data.items);
      }
    });
  };

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  const createUnit = () => {
    param.offset = 0;
    setParam({ ...param });
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return (
    <div className="block p-4 bg-white rounded-md">
      <div className="flex justify-between">
        <p>Residences</p>
        <CreateUnit createUnit={createUnit} />
      </div>
      <div className="flex items-center mt-4 pagination-container">
        <p>Show</p>
        <div className="flex items-center ">
          <Pagination
            onChange={paginate}
            defaultCurrent={1}
            pageSize={param.items}
            current={param.offset + 1}
            total={51}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Details</th>
              <th>Residence</th>
              <th>Tokens</th>
              <th>Main Image</th>
              <th>Name</th>
              <th>Location</th>
              <th>Investor(s)</th>
              <th>Discount (%)</th>
              <th>Original Unit Cost (USD)</th>
              <th>Unit Cost (USD)</th>
              <th>Estimated annual return (USD)</th>
              <th>Guests</th>
              <th>Created on</th>
            </tr>
          </thead>

          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  <UnitDetails unit={d} />
                </td>
                <td>{d.residence}</td>
                <td>{numberWithCommas(d.tokens)}</td>
                <td>
                  <img
                    src={d.images[0]}
                    alt={d.name}
                    className="object-cover h-16"
                  />
                </td>
                <td>{d.name}</td>
                <td>{d.location}</td>
                <td>4</td>{" "}
                <td className="text-end">{numberWithCommas(d.discount)}</td>
                <td className="text-end">{numberWithCommas(d.cost)}</td>
                <td className="text-end">
                  {numberWithCommas(
                    (100 - d.discount) * 0.01 * parseFloat(d.cost)
                  )}
                </td>
                <td className="text-end">
                  {numberWithCommasExtra(
                    (100 - d.discount) * 0.01 * parseFloat(d.cost) * 0.01
                  )}
                </td>
                <td>{numberWithCommas(d.guests)}</td>
                <td>{formatDateTime(d.creation_datetime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        onChange={paginate}
        defaultCurrent={1}
        pageSize={param.items}
        current={param.offset + 1}
        total={total}
      />
    </div>
  );
}

export default Accommodations;
