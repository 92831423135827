import React, { useState } from "react";
import _ from "lodash";
import { Modal, Steps, Checkbox, Upload, message } from "antd";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import postData, { postAuthData } from "../../hooks/fetchApi";
import { Each } from "../../components/Each";
import axios from "axios";

const CheckboxGroup = Checkbox.Group;

const steps = [
  {
    title: "Residence details",
    content: "First-content",
  },
  {
    title: "Amenities",
    content: "Second-content",
  },
  {
    title: "Kind of Place",
    content: "Last-content",
  },
  {
    title: "Images",
    content: "Last-content",
  },
];

const items = steps.map((item) => ({
  key: item.title,
  title: item.title,
}));

const schema = yup
  .object({
    name: yup.string().label("Unit name").required(),
    land_id: yup.number().positive().label("Residence").required(),
    tokens: yup.number().positive().label("PIE Tokens").required(),
    bedrooms: yup.number().positive().label("Bedrooms").required(),
    bathrooms: yup.number().positive().label("Bathrooms").required(),
    guests: yup.number().positive().label("Guests").required(),
    cost: yup.number().positive().label("Unit price").required(),
    duration: yup.number().positive().label("Stay period").required(),
    discount: yup.number().min(0).max(99).label("Disount").required(),
    percentage_reward: yup
      .number()
      .min(0)
      .max(99)
      .label("Reward Percentage")
      .required(),
    description: yup.string().min(10).label("Description").required(),
  })
  .required();

const schema2 = yup
  .object({
    kind: yup.number().positive().label("Kind of unit").required(),
    place: yup.number().positive().label("Type of place").required(),
    space: yup.number().positive().label("Kind of space").required(),
  })
  .required();

function CreateUnit(props) {
  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      land_id: "",
      discount: "",
    },
  });

  const {
    register: register2,
    reset: reset2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema2),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploads, setUploads] = React.useState({});
  const [current, setCurrent] = useState(0);
  const [disable, setDisable] = useState(false);
  const [params, setParams] = useState({});
  const [data, setData] = useState({
    residences: [],
    location: "",
    lat: null,
    lng: null,
    currency: 800,
    amenitiesData: [],
    amenities: [],
    favoritesData: [],
    favorites: [],
    safetyData: [],
    safety: [],
    houseRulesData: [],
    house_rules: [],
    highlightsData: [],
    highlights: [],
    kind: [],
    place: [],
    duration: [],
    space: [],
  });

  const [fileList, setFileList] = useState([]);
  const onChange = (info) => {
    const { status } = info.file;
    setFileList(info.fileList);

    if (status === "done") {
      info.fileList.map((upload) => {
        if (uploads[upload.uid] === undefined)
          uploads[upload.uid] =
            info.file.response.data[info.file.response.data.length - 1];
        setUploads({ ...uploads });
      });
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }

    if (status === "removed") {
      delete uploads[info.file.uid];
      setUploads({ ...uploads });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onSubmit = (data) => {
    setParams({ ...params, ...data });
    setCurrent(current + 1);
  };

  const createUnit = () => {
    if (Object.values(uploads).length < 2)
      message.error("Please upload atleast 2 image");
    else {
      setDisable(true);
      postData({
        service: "create_residence",
        data: {
          land_id: params.land_id,
          tokens: params.tokens,
          name: params.name,
          description: params.description,
          currency: 800,
          place_id: params.place,
          kind_id: params.kind,
          guests: params.guests,
          price: params.cost,
          cost: params.cost,
          bedrooms: params.bedrooms,
          bathrooms: params.bathrooms,
          discount: params.discount,
          percentage_reward: params.percentage_reward,
          images: Object.values(uploads),
          country: 256,
          location: data.location,
          lat: data.lat,
          long: data.lng,
          safety: data.safety,
          favorites: data.favorites,
          amenities: data.amenities,
          highlights: data.highlights,
          rules: data.house_rules,
          space_id: params.space,
          duration: params.duration,
          cleaning: 0,
        },
      }).then((resp) => {
        setDisable(false);
        if (resp.success === 1) {
          message.success(resp.message);
          setIsModalOpen(false);
          props.createUnit();
        } else message.error(resp.message);
      });
    }
  };

  const next = (e) => {
    e.preventDefault();
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const locationChange = ({ lat, lng }) => {
    axios(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=AIzaSyB0dy46oTvw9PivnuoTzy_aa5LDp_8FNIo"
    )
      .then((res) => {
        let locationresult = res.data.results[0].formatted_address;
        setData({ ...data, ...{ location: locationresult, lat, lng } });
      })
      .catch((error) => {
        alert(error);
      });
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "land_id") {
        const residence = _.extend.apply(
          {},
          _.filter(data.residences, { id: parseInt(value.land_id) })
        );
        residence.lng = residence.long;

        locationChange(residence);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const unitData = () => {
    postData({
      service: "get_land",
      data: {
        offset: "0",
        items: "100",
      },
    }).then((resp) => {
      if (resp.success === 1) {
        data.residences = resp.data;
        setData({ ...data });
      }
    });

    postAuthData({
      service: "acc_data",
      data: {},
    }).then((resp) => {
      if (resp.success === 1) {
        const {
          amenities,
          favorites,
          safety,
          house_rules,
          highlights,
          kind,
          place,
          duration,
          space,
        } = resp.data;

        Object.assign(data, {
          amenitiesData: amenities.map((amenity, i) => {
            return {
              value: amenity.id,
              label: amenity.amenity,
            };
          }),
          favoritesData: favorites.map((d, i) => {
            return {
              value: d.id,
              label: d.favorite,
            };
          }),
          safetyData: safety.map((d, i) => {
            return {
              value: d.id,
              label: d.safety,
            };
          }),
          houseRulesData: house_rules.map((d, i) => {
            return {
              value: d.id,
              label: d.rule,
            };
          }),
          highlightsData: highlights.map((d, i) => {
            return {
              value: d.id,
              label: d.highlight,
            };
          }),
          kind,
          place,
          duration,
          space,
        });
        setData({ ...data });
      }
    });
  };

  const boxChange = (list, category) => {
    data[category] = list;
    setData({ ...data });
  };

  React.useEffect(() => {
    unitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form_data">
      <button
        onClick={() => {
          reset();
          reset2();
          setCurrent(0);
          setIsModalOpen(true);
        }}
        className="px-8 py-2 text-sm text-white rounded-md"
      >
        Add unit
      </button>
      <Modal
        title="Add Unit"
        open={isModalOpen}
        footer={[]}
        maskClosable={false}
        width={1000}
        centered
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="mt-6">
          <Steps current={current} items={items} />
          {current === 0 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid md:grid-cols-2 gap-x-10 gap-y-3 form-input">
                <div>
                  <label>Residence</label>
                  <select
                    placeholder="Search to select Land"
                    {...register("land_id")}
                  >
                    <option value="" selected disabled>
                      Search to select Land
                    </option>
                    <Each
                      of={data.residences}
                      render={(item, index) => (
                        <option value={item.id}>{item.name}</option>
                      )}
                    />
                  </select>
                  <p>{errors.land_id?.message}</p>
                </div>
                <div>
                  <label>Unit Name</label>
                  <input
                    type="text"
                    placeholder="Unit Name"
                    {...register("name")}
                  />
                  <p>{errors.name?.message}</p>
                </div>

                <div>
                  <label>PIE Tokens allocation</label>
                  <input
                    type="number"
                    placeholder="Tokens allocation"
                    {...register("tokens")}
                  />
                  <p>{errors.tokens?.message}</p>
                </div>

                <div>
                  <label>Bedrooms</label>
                  <input
                    type="number"
                    placeholder="Number of bedrooms"
                    {...register("bedrooms")}
                  />
                  <p>{errors.bedrooms?.message}</p>
                </div>
                <div>
                  <label>Bathrooms</label>
                  <input
                    type="number"
                    placeholder="Number of bathrooms"
                    {...register("bathrooms")}
                  />
                  <p>{errors.bathrooms?.message}</p>
                </div>
                <div>
                  <label>Guests</label>
                  <input
                    type="number"
                    placeholder="Number of guests"
                    {...register("guests")}
                  />
                  <p>{errors.guests?.message}</p>
                </div>
                <div>
                  <label>Location</label>
                  <input
                    type="text"
                    placeholder="Location"
                    disabled
                    value={data.location}
                  />
                </div>

                <div>
                  <label>Unit Cost (USD)</label>
                  <input
                    type="number"
                    placeholder="Price of the unit"
                    {...register("cost")}
                  />
                  <p>{errors.cost?.message}</p>
                </div>
                <div>
                  <label>Discount</label>
                  <input
                    type="number"
                    placeholder="Discount of unit"
                    {...register("discount")}
                  />
                  <p>{errors.discount?.message}</p>
                </div>
                <div>
                  <label>Reward Percentage on Investment (Referrals)</label>
                  <input
                    type="number"
                    step="any"
                    placeholder="Reward Percentage on Investment"
                    {...register("percentage_reward")}
                  />
                  <p>{errors.percentage_reward?.message}</p>
                </div>

                <div>
                  <label>Duration</label>
                  <select
                    placeholder="Select stay period"
                    {...register("duration")}
                  >
                    <option value="" selected disabled>
                      Select stay period
                    </option>
                    <Each
                      of={data.duration}
                      render={(item, index) => (
                        <option value={item.id}>{item.duration}</option>
                      )}
                    />
                  </select>
                  <p>{errors.duration?.message}</p>
                </div>
              </div>

              <div className="form-input">
                <label>Description</label>
                <textarea
                  rows={3}
                  placeholder="Discount of unit"
                  {...register("description")}
                />
                <p>{errors.description?.message}</p>
              </div>

              <input type="submit" value={"Next"} />
            </form>
          )}

          {current === 1 && (
            <form onSubmit={next}>
              <div className="grid md:grid-cols-3 gap-x-10 gap-y-3 form-input">
                <div>
                  <label>Amenities</label>
                  <CheckboxGroup
                    options={data.amenitiesData}
                    value={data.amenities}
                    onChange={(list) => boxChange(list, "amenities")}
                  />
                </div>

                <div>
                  <label>Favorites</label>
                  <CheckboxGroup
                    options={data.favoritesData}
                    value={data.favorites}
                    onChange={(list) => boxChange(list, "favorites")}
                  />
                </div>
                <div>
                  <label>Safety Options</label>
                  <CheckboxGroup
                    options={data.safetyData}
                    value={data.safety}
                    onChange={(list) => boxChange(list, "safety")}
                  />
                </div>
                <div>
                  <label>House Rules</label>
                  <CheckboxGroup
                    options={data.houseRulesData}
                    value={data.house_rules}
                    onChange={(list) => boxChange(list, "house_rules")}
                  />
                </div>
                <div>
                  <label>Highlights</label>
                  <CheckboxGroup
                    options={data.highlightsData}
                    value={data.highlights}
                    onChange={(list) => boxChange(list, "highlights")}
                  />
                </div>
              </div>
              <div className="flex gap-6 mt-3">
                {current > 0 && (
                  <button className="form-btn" onClick={() => prev()}>
                    Previous
                  </button>
                )}
                <input type="submit" value={"Next"} />
              </div>
            </form>
          )}

          {current === 2 && (
            <form onSubmit={handleSubmit2(onSubmit)}>
              <div className="grid md:grid-cols-2 gap-x-10 gap-y-3 form-input">
                <div>
                  <label>Kind Of Unit</label>
                  <Each
                    of={data.kind}
                    render={(item, index) => (
                      <div className="flex items-center gap-2">
                        <input
                          {...register2("kind")}
                          type="radio"
                          value={item.id}
                          id={`kind${index}`}
                        />
                        <label htmlFor={`kind${index}`}>{item.kind}</label>
                      </div>
                    )}
                  />
                  <p>{errors2.kind?.message}</p>
                </div>

                <div>
                  <label>Type of Place</label>
                  <Each
                    of={data.place}
                    render={(item, index) => (
                      <div className="flex items-center gap-2">
                        <input
                          {...register2("place")}
                          type="radio"
                          value={item.id}
                          id={`place${index}`}
                        />
                        <label htmlFor={`place${index}`}>{item.place}</label>
                      </div>
                    )}
                  />
                  <p>{errors2.place?.message}</p>
                </div>
                <div>
                  <label>Kind Of Space</label>
                  <Each
                    of={data.space}
                    render={(item, index) => (
                      <div className="flex items-center gap-2">
                        <input
                          {...register2("space")}
                          type="radio"
                          value={item.id}
                          id={`space${index}`}
                        />
                        <label htmlFor={`space${index}`}>{item.space}</label>
                      </div>
                    )}
                  />
                  <p>{errors2.space?.message}</p>
                </div>
              </div>
              <div className="flex gap-6 mt-3">
                {current > 0 && (
                  <button className="form-btn" onClick={() => prev()}>
                    Previous
                  </button>
                )}
                <input type="submit" value={"Next"} />
              </div>
            </form>
          )}

          {current === 3 && (
            <div className="mt-5">
              <p className="">Unit Images</p>
              <Upload
                action={`https://api.pieme.info/image_upload`}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                name="image"
                beforeUpload={beforeUpload}
                onPreview={onPreview}
              >
                {fileList.length < 5 && "+ Upload"}
              </Upload>

              <div className="flex gap-3 mt-3">
                {current > 0 && (
                  <button className="form-btn" onClick={() => prev()}>
                    Previous
                  </button>
                )}
                {current === steps.length - 1 && (
                  <button
                    className="form-btn"
                    onClick={createUnit}
                    disabled={disable}
                  >
                    Done
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CreateUnit;
