import { configureStore } from "@reduxjs/toolkit";

import statusReducer from "./features/statusSlice";
import dashboardReducer from "./features/dashboardSlice";

export default configureStore({
  reducer: {
    status: statusReducer,
    dashboard: dashboardReducer,
  },
});
