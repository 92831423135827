import React, { useState } from "react";
import { Modal, Select, message } from "antd";
import postData from "../../hooks/fetchApi";
import NumericInput from "react-numeric-input";
import _, { ceil } from "lodash";

function AddInvestment(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [data, setData] = useState({
    user_id: null,
    unit_id: null,
    unit: null,
    amount: 150,
    users: [],
    units: [],
  });

  const users = () => {
    postData({
      service: "get_users",
      data: {
        property: "active",
        items: "1000",
        offset: "0",
      },
    }).then((resp) => {
      if (resp.success === 1) {
        data.users = resp.data.map((u) => {
          return {
            value: u.user_id,
            label: `${u.first_name} ${u.last_name}`,
          };
        });

        setData({ ...data });
      }
    });
  };

  const units = () => {
    postData({
      service: "incomplete_investments",
      data: {},
    }).then((resp) => {
      if (resp.success === 1) {
        data.units = resp.data.map((d) => {
          d.value = d.id;
          d.label = d.name;
          return d;
        });
        setData({ ...data });
      }
    });
  };

  React.useEffect(() => {
    users();
    units();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (data.unit_id == null) {
      message.error("Please select Unit");
      return;
    } else if (data.user_id == null) {
      message.error("Please select user");
      return;
    } else if (data.amount.toString().trim().length === 0) {
      message.error("Please enter amount");
      return;
    } else if (data.amount < 150) {
      message.error("Amount must be greater than 150");
      return;
    } else {
      setDisable(true);
      postData({
        service: "add_investment",
        data: {
          unit_id: data.unit_id,
          user_id: data.user_id,
          amount: data.amount,
        },
      }).then((resp) => {
        setDisable(false);
        if (resp.success === 1) {
          props.addInvestment();
          setIsModalOpen(false);
          message.success(resp.message);
        } else message.error(resp.message);
      });
    }
  };

  return (
    <div className="form_data">
      <button
        onClick={() => {
          data.unit_id = null;
          data.user_id = null;
          data.amount = 150;
          data.unit = null;
          setData({ ...data });
          setIsModalOpen(true);
        }}
        className="px-8 py-2 text-sm text-white rounded-md"
      >
        Add Investment
      </button>
      <Modal
        title="Add Investment"
        open={isModalOpen}
        footer={[]}
        maskClosable={false}
        width={500}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="mt-6">
          <div>
            <p>Unit</p>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              onChange={(v) => {
                data.unit_id = v;
                data.unit = _.extend.apply({}, _.filter(data.units, { id: v }));
                setData({ ...data });
              }}
              value={data.unit_id}
              placeholder="Search to select Unit"
              optionFilterProp="children"
              filterOption={(input, option) =>
                ((option?.label).toLowerCase() ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={data.units}
            />
          </div>

          <div className="mt-6">
            <p>User</p>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              onChange={(v) => {
                data.user_id = v;
                setData({ ...data });
              }}
              value={data.user_id}
              placeholder="Search to select User"
              optionFilterProp="children"
              filterOption={(input, option) =>
                ((option?.label).toLowerCase() ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={data.users}
            />
          </div>

          {data.unit !== null && (
            <div className="mt-4">
              <p>Amount</p>
              <NumericInput
                className="w-full p-2 border"
                min={150}
                step={1}
                max={ceil(data.unit.balance / 0.97)}
                value={data.amount}
                // eslint-disable-next-line
                style={false}
                onBlur={(e) => {
                  data.amount = e.target.value;
                  setData({ ...data });
                }}
              />
            </div>
          )}

          <button
            onClick={onSubmit}
            disabled={disable}
            className="px-4 py-2 mt-6 text-white rounded-md"
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default AddInvestment;
