export function numberWithCommas(x) {
  x = Math.floor(x);
  if (x !== undefined)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return "";
}

export function numberWithCommasExtra(x) {
  x = toFixedIfNecessary(x);
  if (x !== undefined)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return "";
}

/**
 * Attach zero
 * @param {*} n
 */
const attachZero = (n) => {
  if (Number(n) < 10) return `0${n}`;
  return `${n}`;
};

export function currenctDate() {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  return `${attachZero(year)}-${attachZero(month)}-${attachZero(day)}`;
}
function toFixedIfNecessary(value) {
  return +parseFloat(value).toFixed(3);
}

export function formatDate(date) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(" ");

  // Convert the month abbreviation to uppercase
  const capitalizedMonth = month;

  // Return the formatted date with uppercase month abbreviation and desired format
  return `${day} ${capitalizedMonth} ${year}`;
}

export function formatDateTime(date) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  // Split the formatted date into day, month, and year parts
  const [month, day, year] = formattedDate.split(" ");

  // Convert the month abbreviation to uppercase
  const capitalizedMonth = month;

  const hours = String(new Date(date).getHours()).padStart(2, "0");
  const minutes = String(new Date(date).getMinutes()).padStart(2, "0");
  const seconds = String(new Date(date).getSeconds()).padStart(2, "0");

  // Return the formatted date with uppercase month abbreviation and desired format
  return `${day} ${capitalizedMonth} ${year} ${hours}:${minutes}:${seconds}`;
}
