import React from "react";

function Header() {
  return (
    <div className="sticky top-0 z-10 p-4 bg-white">
      <p>Header</p>
    </div>
  );
}

export default Header;
