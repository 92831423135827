import { Modal, Select, message } from "antd";
import React, { useState } from "react";
import postData from "../../hooks/fetchApi";
import NumericInput from "../../components/numeric-input";

function RewardToken({ reload }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({
    users: [],
    user_id: null,
    amount: null,
  });

  const users = () => {
    postData({
      service: "get_users",
      data: {
        property: "active",
        items: "1000",
        offset: "0",
      },
    }).then((resp) => {
      if (resp.success === 1) {
        data.users = resp.data.map((u, i) => {
          return {
            value: u.user_id,
            label: `${u.first_name} ${u.last_name}`,
          };
        });

        setData({ ...data });
      }
    });
  };

  React.useEffect(() => {
    users();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (data.user_id === null) {
      message.error("Please select the user");
      return false;
    } else if (data.amount == null) {
      message.error("Please enter the number of tokens");
      return false;
    } else if (data.amount <= 0) {
      message.error("Tokens to be allocated cannot be less than 0 (zero)");
      return false;
    } else {
      postData({
        service: "allocate_tokens",
        data: {
          user_id: data.user_id,
          amount: data.amount,
        },
      }).then((resp) => {
        reload();
        if (resp.success === 1) message.success(data.message);
        else message.error(data.message);
        setIsModalOpen(false);
      });
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          data.user_id = null;
          data.amount = null;

          setData({ ...data });
          setIsModalOpen(true);
        }}
        className="px-8 py-2 text-sm text-white rounded-md"
      >
        Award Tokens
      </button>

      <Modal
        title="Award Tokens"
        open={isModalOpen}
        footer={[]}
        maskClosable={false}
        width={500}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="mt-6">
          <div>
            <p>User</p>
            <Select
              showSearch
              style={{
                width: "100%",
              }}
              onChange={(v) => {
                data.user_id = v;
                setData({ ...data });
              }}
              value={data.user_id}
              placeholder="Search to select User"
              optionFilterProp="children"
              filterOption={(input, option) =>
                ((option?.label).toLowerCase() ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={data.users}
            />
          </div>
          <div className="mt-4">
            <p>Amount</p>
            <NumericInput
              placeholder="Tokens allocation"
              style={{
                width: "100%",
              }}
              value={data.amount}
              onChange={(v) => {
                data.amount = v;
                setData({ ...data });
              }}
              suffix="PIE Token amoint"
            />
          </div>

          <button
            onClick={onSubmit}
            className="px-8 py-2 mt-6 text-sm text-white rounded-md"
          >
            Allocation Tokens
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default RewardToken;
