import { Button, Modal, Col, Image, Row } from "antd";
import React, { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import _ from "lodash";
import { numberWithCommas } from "../../utils/utils";

function importAll(r) {
  let images = {};
  // eslint-disable-next-line array-callback-return
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
);

const extraIcons = {
  1: images["pool.png"],
  2: images["bathtub.png"],
  3: images["bbq.png"],
  4: images["fire-place.png"],
  5: images["parking.png"],
  6: images["gym.png"],
};
const safetyIcons = {
  1: images["smoke-alarm.png"],
  2: images["first-aid.png"],
  3: images["detector.png"],
  4: images["extinguisher.png"],
};
const rulesIcons = {
  1: images["pets.png"],
  2: images["pool.png"],
  3: images["pool.png"],
  4: images["party.png"],
  5: images["no-smoking.png"],
};

const google_map_api = "AIzaSyB0dy46oTvw9PivnuoTzy_aa5LDp_8FNIo";

function UnitDetails({ unit }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const acc = unit;

  const [libraries] = React.useState(["places"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: google_map_api,
    libraries: libraries,
  });

  const center = {
    lat: acc.latitude,
    lng: acc.longitude,
  };
  return (
    <>
      <Button
        type="primary"
        icon={<EyeOutlined />}
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        title="Add Unit"
        open={isModalOpen}
        footer={[]}
        maskClosable={false}
        width={1000}
        style={{ top: 0 }}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          <Image.PreviewGroup>
            <div className="gap-3 acc-images">
              <div>
                <Image
                  src={acc.images[0]}
                  width={"100%"}
                  height={"100%"}
                  alt="main"
                />
              </div>
              <div>
                <Image
                  src={acc.images[1]}
                  width={"100%"}
                  height={"100%"}
                  alt="main"
                />
              </div>
              {/* <div className="top-bottom-images">
                <div className="h-full">
                  <div>
                    <Image
                      width={"100%"}
                      height={"100%"}
                      src={acc.images[1]}
                      alt="main"
                    />
                  </div>
                  <div>
                    <Image
                      width={"100%"}
                      height={"100%"}
                      src={acc.images[2]}
                      alt="main"
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div style={{ display: "none" }}>
              {_.map(_.drop(acc.images, 5), (img) => (
                <Image width={"100%"} height={"100%"} src={img} alt="main" />
              ))}
            </div>
          </Image.PreviewGroup>

          <div className="acc-details">
            <h4>
              {acc.currency} {numberWithCommas(acc.price)}
            </h4>
            <h2>About the unit</h2>
            <p>{acc.description}</p>

            <h2>Cleaning fee</h2>
            <p>
              {acc.converted_currency + " "}
              {acc.cleaning}
            </p>
            <div>
              {acc.amenities.length > 0 && (
                <>
                  <h5>What this place offers</h5>
                  <Row style={{ padding: 0 }}>
                    {_.map(acc.amenities, (a, k) => (
                      <Col
                        key={k}
                        style={{
                          padding: 0,
                          display: "flex",
                        }}
                        span={8}
                      >
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            marginTop: "-3px",
                            marginRight: "5px",
                            filter: "var(--icon-color)",
                          }}
                          src={extraIcons[a.id]}
                          alt="icon"
                        />
                        <p> {a.amenity}</p>
                      </Col>
                    ))}
                  </Row>
                </>
              )}

              {acc.safety.length > 0 && (
                <>
                  <h5>Safety and security</h5>
                  <Row>
                    {_.map(acc.safety, (a, k) => (
                      <Col
                        key={k}
                        style={{
                          padding: 0,
                          display: "flex",
                        }}
                        span={8}
                      >
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            marginTop: "-3px",
                            marginRight: "5px",
                            filter: "var(--icon-color)",
                          }}
                          src={safetyIcons[a.id]}
                          alt="icon"
                        />
                        <p>{a.safety}</p>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {acc.rules.length > 0 && (
                <>
                  <h5>House Rules</h5>
                  <Row>
                    {_.map(acc.rules, (a, k) => (
                      <Col
                        key={k}
                        style={{
                          padding: 0,
                          display: "flex",
                        }}
                        span={8}
                      >
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            marginTop: "-3px",
                            marginRight: "5px",
                            filter: "var(--icon-color)",
                          }}
                          src={rulesIcons[a.id]}
                          alt="icon"
                        />
                        <p>{a.rule}</p>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              <div>
                <h5>Where you’ll be</h5>
                <p> {"=> " + acc.location}</p>
                {isLoaded && (
                  <GoogleMap
                    zoom={15}
                    center={center}
                    mapContainerStyle={{ width: "100%", height: "300px" }}
                  >
                    <Marker
                      shape="MarkerShapePoly"
                      cursor="pointer"
                      position={center}
                    />
                    {/* <Circle radius={300} center={center} /> */}
                  </GoogleMap>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UnitDetails;
