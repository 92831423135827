import { Button, Image, Modal, Typography } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons';
import React, { useState } from 'react'
import { EyeOutlined } from "@ant-design/icons";
import { numberWithCommas } from '../../utils/utils';
import _ from 'lodash';
import postData from '../../hooks/fetchApi';



const { Text } = Typography;
const { confirm } = Modal;
function BankPayDetails({ data }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const approvePayment = () => {
        confirm({
            title: 'Do you want to approve this transaction?',
            icon: <ExclamationCircleFilled />,
            content: 'After approving, this implies that payment was received',
            onOk() {
                setIsModalOpen(false)
                postData({
                    service: "approve_bank_payment",
                    data: {
                        transaction_id: data.transaction_id
                    },
                }).then((data) => {
                    window.location.reload()
                });
            },
            onCancel() { },
        });
    }

    const declinePayment = () => {
        confirm({
            title: 'Do you want to decline this transaction?',
            icon: <ExclamationCircleFilled />,
            onOk() {
                setIsModalOpen(false)
                postData({
                    service: "decline_bank_payment",
                    data: {
                        transaction_id: data.transaction_id
                    },
                }).then((data) => {
                    window.location.reload()
                });
            },
            onCancel() { },
        });
    }

    return (
        <>
            <Button
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => {
                    console.log(data)
                    setIsModalOpen(true)
                }}
            />
            <Modal
                title="Bank Payment Details"
                open={isModalOpen}
                footer={[]}
                maskClosable={false}
                width={800}
                onOk={() => {
                    setIsModalOpen(false);
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
            >


                <Image.PreviewGroup>
                    <div className='grid grid-cols-2 gap-3'>
                        {_.map(data.bank_proof, (p) => <Image src={p} className='object-contain w-full max-h-64' />)}
                    </div>

                </Image.PreviewGroup>
                {data.bank_proof.length === 0 && <p className='mt-5 text-lg font-medium text-red-500 ms-3'>No proof submitted yet</p>}
                <table>
                    <tbody>
                        <tr>
                            <td>Transaction ID</td>
                            <td>{data.transaction_id}</td>
                        </tr>
                        <tr>
                            <td>Unit</td>
                            <td>{data.name}</td>
                        </tr>
                        <tr>
                            <td>Location</td>
                            <td>{data.location}</td>
                        </tr>
                        <tr>
                            <td>Amount</td>
                            <td>{numberWithCommas(data.amount)}</td>
                        </tr>
                        <tr>
                            <td>Fee</td>
                            <td>{numberWithCommas(data.fee)}</td>
                        </tr>
                        <tr>
                            <td>Total (USD)</td>
                            <td>{numberWithCommas(parseFloat(data.amount) + parseFloat(data.fee))}</td>
                        </tr>
                        <tr>
                            <td>Total (UGX)</td>
                            <td>{numberWithCommas(parseFloat(data.curreny_amount) + parseFloat(data.currency_fee))}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>
                                {data.status === 0 && "Pending"}
                                {data.status === 1 && <Text type="success">Successful</Text>}
                                {data.status === 2 && <Text type="danger">Failed</Text>}
                                {data.status === 3 && <Text type="danger">Cancelled</Text>}
                            </td>
                        </tr>

                        <tr>
                            <td>Contract</td>
                            <td>{data.contract !== null && <a href={`http://pieme.info/documents/${data.contract}`} target="_blank" rel="noreferrer" className="font-medium text-blue-500">Contract PDF</a>}</td>
                        </tr>

                        <tr>
                            <td>Message</td>
                            <td>{data.message}</td>
                        </tr>
                        <tr>
                            <td>User</td>
                            <td>{data.first_name} {data.last_name}</td>
                        </tr>
                        <tr>
                            <td>Email address</td>
                            <td>{data.email_id}</td>
                        </tr>
                        <tr>
                            <td>Phone Number</td>
                            <td>{data.user_phone}</td>
                        </tr>
                    </tbody>
                </table>

                {data.status === 0 && <div className='flex gap-5'>
                    <Button type="primary" onClick={() => approvePayment()}>Approve</Button>
                    <Button type="primary" danger onClick={() => declinePayment()}>Decline</Button>
                </div>}

            </Modal>
        </>
    )
}

export default BankPayDetails
