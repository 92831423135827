import React, { useState, useEffect } from "react";
import postData from "../../hooks/fetchApi";
import { status } from "../../features";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { formatDateTime } from "../../utils/utils";
import { Pagination } from "antd";

function Users() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [param, setParam] = useState({
    property: "active",
    items: "10",
    offset: "0",
  });

  const getData = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "get_users",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success === 1) {
        setData(data.data);
        setTotal(data.items);
      }
    });
  };

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return (
    <div className="p-4 bg-white rounded-md">
      <p>Users ({total})</p>
      <div className="flex items-center mt-4 pagination-container">
        <p>Show</p>
        <div className="flex items-center ">
          <Pagination
            onChange={paginate}
            defaultCurrent={1}
            pageSize={param.items}
            current={param.offset + 1}
            total={51}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Username</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Next of kin</th>
              <th>Verified</th>
              <th>Joined on</th>
            </tr>
          </thead>

          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{param.offset * param.items + (i + 1)}</td>
                <td>
                  {d.first_name} {d.last_name}
                </td>
                <td>{d.username}</td>
                <td>
                  {d.mobile_code}
                  {d.phone_no}
                </td>
                <td>{d.email_id}</td>
                <td>{d.nok}</td>
                <td>{d.verification_status}</td>
                <td>{formatDateTime(d.creation_datetime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={paginate}
        defaultCurrent={1}
        pageSize={param.items}
        current={param.offset + 1}
        total={total}
      />
    </div>
  );
}

export default Users;
