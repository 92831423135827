import React from "react";
import postData from "../../hooks/fetchApi";
import { message } from "antd";
import useToken from "../../utils/user-token";

function Login() {
  const { setToken } = useToken();
  const input_class = "border text-sm p-3";
  const [disable, setDisable] = React.useState(false);
  const [data, setData] = React.useState({
    email_id: "",
    password: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();

    setDisable(true);
    postData({
      service: "login",
      data,
    }).then((resp) => {
      setDisable(false);
      if (resp.success !== undefined && resp.success === 1) {
        setToken(resp.token);
        window.location.reload(false);
      } else {
        message.error(resp.message);
      }
    });
  };

  return (
    <div className="flex items-center justify-center w-full h-screen side-bg">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-4 p-6 bg-white w-96">
          <p className="text-center">Login here</p>

          <input
            type="email"
            required
            className={input_class}
            placeholder="Email address"
            value={data.email_id}
            onChange={(e) => {
              data.email_id = e.target.value;
              setData({ ...data });
            }}
          />
          <input
            type="password"
            required
            className={input_class}
            placeholder="Password"
            value={data.password}
            onChange={(e) => {
              data.password = e.target.value;
              setData({ ...data });
            }}
          />
          <button
            type="submit"
            disabled={disable}
            className="bg-blue-500 p-3.5 text-sm text-white"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
