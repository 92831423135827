
import React, { useState, useEffect } from "react";
import postData from "../../hooks/fetchApi";
import { status } from "../../features";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Typography } from "antd";
import { IoReloadCircleSharp } from "react-icons/io5";
import { formatDateTime, numberWithCommas } from "../../utils/utils";
import { Pagination } from "antd";
import BankPayDetails from "./bank-pay-details";

const { Text } = Typography;
function BankPayments() {
    document.title = "Bank Payments"
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(10);
    const [param, setParam] = useState({
        items: "10",
        offset: "0",
    });

    const getData = () => {
        dispatch(status.setStatus(true));
        postData({
            service: "bank_payments",
            data: param,
        }).then((data) => {
            dispatch(status.setStatus(false));
            if (data.success === 1) {
                setData(data.data);
                setTotal(data.items);
            }
        });
    };

    const paginate = (page, pageSize) => {
        param.offset = parseInt(page) - 1;
        param.items = pageSize;
        setParam({ ...param });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param]);

    return (
        <div className="p-4 bg-white rounded-md">
            <p>Bank Payments ({numberWithCommas(total)})</p>
            <div className="flex justify-between">
                <div className="flex items-center mt-4 pagination-container">
                    <p>Show</p>
                    <div className="flex items-center ">
                        <Pagination
                            onChange={paginate}
                            defaultCurrent={1}
                            pageSize={param.items}
                            current={param.offset + 1}
                            total={51}
                        />
                    </div>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Actions</th>
                            <th>Transaction ID</th>
                            <th>Unit</th>
                            <th>Amount (USD)</th>
                            <th>Fee (USD)</th>
                            <th>Total (USD)</th>
                            <th>Status</th>
                            <th>User</th>
                            <th>Contract</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Message</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        {_.map(data, (d, i) => (
                            <tr key={i}>
                                <td>{param.offset * param.items + (i + 1)}</td>
                                <td><BankPayDetails data={d} /></td>
                                <td>{d.transaction_id}</td>
                                <td>{d.name}</td>
                                <td className="text-end">{numberWithCommas(d.amount)}</td>
                                <td className="text-end">{numberWithCommas(d.fee)}</td>
                                <td className="text-end">
                                    {numberWithCommas(parseFloat(d.amount) + parseFloat(d.fee))}
                                </td>
                                <td>
                                    {d.status === 0 && "Pending"}
                                    {d.status === 1 && <Text type="success">Successful</Text>}
                                    {d.status === 2 && <Text type="danger">Failed</Text>}
                                    {d.status === 3 && <Text type="danger">Cancelled</Text>}
                                </td>
                                <td>
                                    {d.first_name} {d.last_name}
                                </td>
                                <td>{d.contract !== null && <a href={`http://pieme.info/documents/${d.contract}`} target="_blank" rel="noreferrer" className="font-medium text-green-500">Contract</a>}</td>
                                <td>{d.email_id}</td>
                                <td>+{d.user_phone}</td>
                                <td>{d.message}</td>

                                <td>{formatDateTime(d.creation_datetime)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                onChange={paginate}
                defaultCurrent={1}
                pageSize={param.items}
                current={param.offset + 1}
                total={total}
            />
        </div>
    );
}

export default BankPayments;
