import React, { useState, useEffect } from "react";
import postData from "../../hooks/fetchApi";
import { status } from "../../features";
import { useDispatch } from "react-redux";

import _ from "lodash";
import { Typography } from "antd";
import { formatDateTime, numberWithCommas } from "../../utils/utils";
import { Pagination } from "antd";
import RewardToken from "./reward-token";

const { Text } = Typography;
function Tokens() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [param, setParam] = useState({
    items: "10",
    offset: "0",
  });

  const getData = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "token_allocations",
      data: param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success === 1) {
        setData(data.data);
        setTotal(data.items);
      }
    });
  };

  const reload = () => {
    param.offset = 0;
    setParam({ ...param });
  };

  const paginate = (page, pageSize) => {
    param.offset = parseInt(page) - 1;
    param.items = pageSize;
    setParam({ ...param });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  return (
    <div className="p-4 bg-white rounded-md">
      <p>Token Allocation ({numberWithCommas(total)})</p>
      <div className="flex flex-row justify-between">
        <div className="flex items-center mt-4 pagination-container">
          <p>Show</p>
          <div className="flex items-center ">
            <Pagination
              onChange={paginate}
              defaultCurrent={1}
              pageSize={param.items}
              current={param.offset + 1}
              total={51}
            />
          </div>
        </div>
        <RewardToken reload={reload} />
      </div>
      <div className="overflow-x-auto">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Transaction ID</th>
              <th>Amount (PIE)</th>
              <th>Status</th>
              <th>Message</th>
              <th>User</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Date</th>
            </tr>
          </thead>

          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{param.offset * param.items + (i + 1)}</td>
                <td>{d.transaction_id}</td>
                <td className="text-end">{numberWithCommas(d.amount)}</td>
                <td>
                  {d.status === 0 && "Pending"}
                  {d.status === 1 && <Text type="success">Successful</Text>}
                  {d.status === 2 && <Text type="danger">Failed</Text>}
                </td>
                <td>{d.message}</td>
                <td>
                  {d.user.first_name} {d.user.last_name}
                </td>
                <td>{d.user.email_id}</td>
                <td>+{d.user.phone_no}</td>
                <td>{formatDateTime(d.creation_datetime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        onChange={paginate}
        defaultCurrent={1}
        pageSize={param.items}
        current={param.offset + 1}
        total={total}
      />
    </div>
  );
}

export default Tokens;
