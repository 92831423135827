import React, { useState, useEffect } from "react";
import postData from "../../hooks/fetchApi";
import { status } from "../../features";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { formatDate } from "../../utils/utils";

function Residences() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const land_param = {
    offset: "0",
    items: "10",
  };

  const getData = () => {
    dispatch(status.setStatus(true));
    postData({
      service: "get_land",
      data: land_param,
    }).then((data) => {
      dispatch(status.setStatus(false));
      if (data.success === 1) {
        setData(data.data);
      }
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-4 bg-white rounded-md">
      <p>Residences</p>
      <div className="">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th className="sized">Name</th>
              <th>Location</th>
              <th>Holder/Owner</th>
              <th>Dimensions</th>
              <th>Created</th>
            </tr>
          </thead>

          <tbody>
            {_.map(data, (d, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{d.name.trim()}</td>
                <td>{d.location.trim()}</td>
                <td>{d.owner.first_name}</td>
                <td>
                  {d.size_width} x {d.size_height} ft
                </td>
                <td>{formatDate(d.creation_datetime)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Residences;
